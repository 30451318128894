import { getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { normalizeResource } from "../routes/util"
import type { RootState } from "../store/store"
import type { SerializedError } from "@reduxjs/toolkit"
import type { BaseQueryFn } from "@reduxjs/toolkit/query"

type CopiedResponse = {
    ok: boolean
    status: number
    statusText: string
    headers: Record<string, string>
    redirected: boolean
    url: string
    type: ResponseType
}

type ParsedResponse<T> = CopiedResponse & {
    jsonData: T
    error?: string
}

// type predicate to check isCustomRtkError

export const isCustomRtkError = (err: unknown): err is CustomRtkError => {
    return (
        typeof err === "object" &&
        err != null &&
        "data" in err &&
        "status" in err
    )
}
export interface CustomRtkError {
    data: {
        error?: string
        validation_error: string
    }
    status?: number
}

function getResponseCopyWithoutBody(response: Response): CopiedResponse {
    const properties = {
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        headers: {},
        redirected: response.redirected,
        url: response.url,
        type: response.type,
    } as CopiedResponse
    response.headers.forEach((value, key) => {
        properties.headers[key] = value
    })
    return properties
}

function toFormData(data: Record<string, string> | undefined): FormData {
    const formData = new FormData()
    if (data !== undefined) {
        for (const key of Object.keys(data)) {
            formData.append(key, data[key])
        }
    }
    return formData
}

async function myFetch<T>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    url: string,
    body?: Record<string, string> | FormData,
    options?: RequestInit,
): Promise<ParsedResponse<T>> {
    const defaults: RequestInit = {
        method: method,
        mode: "same-origin",
        credentials: "same-origin",
    }

    if (method !== "GET") {
        const csrfToken = getCookieOrUndefined("csrftoken")
        if (csrfToken === undefined) {
            throw new Error("CSRF Token missing for non-GET Fetch")
        }
        defaults.headers = {
            ...defaults.headers,
            "X-CSRFToken": csrfToken,
            "X-Requested-With": "XMLHttpRequest",
        }
    }
    // there are some issues with mocking post requests with FormData in tests
    const formData =
        process.env.NODE_ENV === "test"
            ? body
            : body && (body instanceof FormData ? body : toFormData(body))
    const resp = await fetch(url, {
        ...defaults,
        ...options,
        body: formData as FormData,
        headers: {
            ...defaults.headers,
            ...(options && options.headers),
        },
    })

    if (resp.status === 401) {
        throw new Error("Unauthorized")
    }
    if (resp.status === 500) {
        throw new Error("Internal server error.")
    }
    if (resp.status === 403) {
        const text = await resp.text()
        if (text.includes("CSRF verification failed")) {
            // CSRF errors respond with full HTML document
            throw new Error("CSRF verification failed. Request aborted.")
        } else if (text.includes("<!DOCTYPE")) {
            // Catch other 403's that respond with full HTML document
            throw new Error("Forbidden (403)")
        }
        throw new Error(text)
    }

    // response properties are not enumerable so we need to copy them manually
    const respPropCopy = getResponseCopyWithoutBody(resp)
    try {
        const data = await resp.json()
        if (!resp.ok) {
            return {
                ...respPropCopy,
                jsonData: data,
                error: JSON.stringify(data),
            }
        }
        if (Boolean(data.error)) {
            throw new Error(data.error)
        } else {
            return {
                ...respPropCopy,
                jsonData: data,
            }
        }
    } catch (e) {
        return {
            ...respPropCopy,
            jsonData: {} as T,
            error: e.message,
        }
    }
}

const cbBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: "" },
    ): BaseQueryFn<
        {
            url: string
            method: "GET" | "POST" | "PUT" | "DELETE"
            data?: Record<string, string>
            params?: RequestInit
        },
        object,
        SerializedError | CustomRtkError | Error
    > =>
    async ({ url, method, data, params }, api) => {
        baseUrl = normalizeResource(
            (api.getState() as RootState).user.languageCode,
            baseUrl,
        )
        try {
            const result = await myFetch(method, baseUrl + url, data, params)
            if (!result.ok) {
                // so that we can use that in matchRjected in redux toolkit
                // eslint-disable-next-line no-throw-literal
                throw {
                    status: result.status,
                    data: result.jsonData,
                    message: result.error,
                }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return { data: result.jsonData as any }
        } catch (error) {
            if (error instanceof Error) {
                return {
                    error: {
                        message: error.message,
                    },
                }
            }
            return {
                error,
            }
        }
    }

const fetchGet = myFetch.bind(null, "GET")
const fetchPost = myFetch.bind(null, "POST")
const fetchPut = myFetch.bind(null, "PUT")
const fetchDelete = myFetch.bind(null, "DELETE")

export { cbBaseQuery, fetchGet, fetchPost, fetchPut, fetchDelete, myFetch }
